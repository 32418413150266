function Dashboard() {
  return (
    <iframe
      title="Windyheights_Access_Control_System_P1 - Dashboard"
      width="100%"
      height="900px"
      src="https://app.powerbi.com/view?r=eyJrIjoiNmIxMDlhMjAtMzY2OS00NDlkLTkxNzUtNzY1YzRiZGRiM2QxIiwidCI6IjUyMThlM2IxLWM0YmEtNDcwNi05OWY5LWE2YjBkNTU0OThiNCJ9"
      frameborder="0"
      allowFullScreen="true"
    ></iframe>
  );
}
export default Dashboard;
