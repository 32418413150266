import MUIDataTable from "mui-datatables";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import React, { useState, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Card from "@mui/material/Card";
import DataServices from "../Api/DataServices";
import Tooltip from "@mui/material/Tooltip";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/GetApp";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

var monthName = new Array(
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
);
var d = new Date();
let monthData = [];

const columns = [
  {
    name: "vehicleName",
    label: "Vehicle Number",
    options: {
      filter: true,
      sort: false,
    },
  },

  {
    name: "visitorType",
    label: "Category",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "entryTime",
    label: "Entry",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "exitTime",
    label: "Exit",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "blockNo",
    label: "Block",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "floorNo",
    label: "Floor",
    options: {
      filter: true,
      sort: false,
    },
  },

  {
    name: "unitNo",
    label: "Unit",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "firstName",
    label: "Name",
    options: {
      filter: true,
      sort: false,
    },
  },

  {
    name: "mobileNumber",
    label: "Contact",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "overstay",
    label: "Overstay",
    options: {
      filter: true,
      sort: false,
    },
  },
];

const category = [
  `All`,
  `Contractor`,
  `Delivery`,
  `Pickup/Dropoff`,
  `Resident`,
  `Unregistered`,
  `Visitor`,
];

export default function EntryExit() {
  const csvLink = useRef();
  const [selectSearch, setSelectSearch] = React.useState("");
  const [selectSearchText, setSelectSearchText] = React.useState("");
  const [list, setList] = React.useState([]);
  const [page, setPage] = useState(0);
  const [details, setDetails] = React.useState([]);
  const count = 100;
  const [downloadcsv, setDownloadcsv] = React.useState([]);
  const [inputSearch, setInputSearch] = useState({
    name: "",
    block: "",
    contactNo: "",
    vehicleNo: "",
    monthWise: "",
    category: "",
  });
  const [bDownloadReady, setDownloadReady] = useState(false);
  const [progress, setProgress] = useState(false);
  const options = {
    //filter: true,
    //serverSide: true,
    selectableRows: false,
    search: false,
    count: count,
    page: page,
    filter: false,
    download: false,
    serverSide: true,
    /* downloadOptions: {
            filename: new Date(),
            // new API change added here
            customCSVdata: details,
            filterOptions: { useDisplayedColumnsOnly: true },
            useDisplayedRowsOnly: true,

        },
        onDownload: (buildHead, buildBody, columns, data) => {
            return downloadCsvByApi();
        }, */
    print: false,
    viewColumns: false,
    //rowsPerPageOptions: [10],
    filterType: "custom",
    responsive: "vertical",
    filterOptions: {
      filter: {
        title: "",
        reset: "",
      },
    },
    searchPlaceholder: "Search by Vehicle Number",
    pagination: list.length > 9 ? true : false,
    rowsPerPage: 10,
    rowsPerPageOptions: [10],
    textLabels: {
      filter: {
        title: "",
        reset: "",
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          if (list && list.length > 9) {
            changePage(tableState.page);
          }
          break;
        case "search":
          onSearch(tableState.searchText);
          break;
        case "resetFilters":
          break;
      }
    },
  };

  /*  const getMuiTheme = () =>
     createTheme({
       components: {
         MUIDataTableBodyCell: {
             head: {
                 backgroundColor: "red !important"
             },
           styleOverrides:{ root: {
             padding: '8px',
             backgroundColor: '#CDCAC6',
           }}
         },
         MuiTableCell: {
             head: {
                 backgroundColor: "red !important"
             }
         },
         MuiToolbar: {
           styleOverrides:{regular: {
             minHeight: '8px',
           }}
         }
       }
     });
  */
  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTable: {
          styleOverrides: {
            root: {
              backgroundColor: "#282c34 !important",
            },
            paper: {
              boxShadow: "none",
            },
          },
        },
        MUIDataTableBodyRow: {
          styleOverrides: {
            root: {
              "&:nth-child(odd)": {
                backgroundColor: "#A0D1FF !important",
              },
              "&:nth-child(even)": {
                backgroundColor: "#41A4FF !important",
              },
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              padding: "8px",
              //backgroundColor: '#CDCAC6 ',
            },
          },
        },
        MuiToolbar: {
          styleOverrides: {
            root: {
              backgroundColor: "#666666 !important",
              color: `white`,
            },
            icon: {
              color: `white ! important`,
              backgroundColor: `white ! important`,
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            head: {
              backgroundColor: "#666666 !important",
              color: `white`,
              //padding: "0px 0px",
              fontWeight: `bold`,
              fontSize: `15px`,
              //justifyContent: 'center'
            },
          },
        },
        MUIDataTableSelectCell: {
          styleOverrides: {
            headerCell: {
              backgroundColor: "blue !important",
            },
          },
        },
        MuiTableFooter: {
          styleOverrides: {
            root: {
              backgroundColor: `#666666 !important`,
              "& .MuiToolbar-root": {
                //backgroundColor: 'white',
                color: `white`,
              },
            },
          },
        },
      },
    });

  const CustomTooltip = ({ children, ...props }) => {
    return (
      <Tooltip
        {...props}
        title={props.title === "Filter Table" ? "Search" : props.title}
      >
        {children}
      </Tooltip>
    );
  };

  function regFunction() {
    let data = {
      pageNo: 0,
    };
    registerTable(data);
  }

  useEffect(() => {
    monthData = [];
    var x = new Date();
    for (let i = 0; i <= 11; i++) {
      /* monthData.push(monthName[d.getMonth()] + '-' + x.getFullYear());
            d.setMonth(d.getMonth() - 1); */
      var future = new Date(x.getFullYear(), x.getMonth() - i, 1);
      var month = monthName[future.getMonth()];
      var year = future.getFullYear();
      monthData.push(month + "-" + year);
    }
  }, []);

  function changePage(page) {
    setPage(page);
  }

  useEffect(() => {
    /* let data = {
            pageNo: page
        }
        registerTable(data) */
    onSearch();
  }, [page]);

  useEffect(() => {
    if (csvLink && csvLink.current && bDownloadReady) {
      csvLink.current.link.click();
      setProgress(false);
      setDownloadReady(false);
    }
  }, [bDownloadReady]);

  function registerTable(data) {
    DataServices.getEntryExit(data).then((d) => {
      let data = d && d.data && d.data.data && d.data.data.content;
      let element = d && d.data && d.data.data && d.data.data.totalElements;
      setList(data);
      setDetails(arrayToCSV(data));
    });
  }

  const download = (data) => {
    DataServices.downloadExcel(data).then((res) => {
      let details = res && res.data;
      setDetails(details);
    });
  };

  const SearchByGlobal = () => {
    /*  if(selectSearch.length == 0 || (selectSearch == `category` && selectSearchText ==`All`)){
             regFunction();
         }
         else{
             onSearch(selectSearchText, selectSearch)
         }      */
    onSearch();
  };

  const handleSelectSearch = (e) => {
    setSelectSearch(e.target.value);
  };

  const handleFilterReset = () => {
    setSelectSearchText("");
    setSelectSearch("");
    setInputSearch({
      name: "",
      block: "",
      contactNo: "",
      vehicleNo: "",
      monthWise: "",
      category: "",
    });
    regFunction();
  };

  const handleChange = (e) => {
    setInputSearch({ ...inputSearch, [e.target.id]: e.target.value });
  };

  const handleCategoryChange = (e) => {
    setInputSearch({ ...inputSearch, category: e.target.value });
  };

  const handleMonthChange = (e) => {
    setInputSearch({ ...inputSearch, monthWise: e.target.value });
  };

  const onSearch = () => {
    let data = {
      pageNo: page,
      field: inputSearch,
    };
    DataServices.searchEntryExit(data).then((d) => {
      let data = d && d.data && d.data.data && d.data.data.content;
      setList(data);
      setDetails(arrayToCSV(data));
    });
  };

  const downloadCsvByApi = async () => {
    setProgress(true);
    let data = {
      pageNo: page,
      field: inputSearch,
    };
    await DataServices.downloadEntryExitExcel(data).then((res) => {
      let details = res && res.data;
      setDownloadcsv(details);
      setDownloadReady(true);
    });
  };

  function arrayToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";

    return array.reduce((str, next) => {
      str +=
        `${Object.values(next)
          .map((value) => `"${value}"`)
          .join(",")}` + "\r\n";
      return str;
    }, str);
  }

  return (
    <React.Fragment>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{ paddingLeft: `30px`, paddingRight: `30px` }}
        >
          <Grid container>
            <Grid item xs={11} md={11}>
              <h3 style={{ margin: `0px`, padding: `10px`, fontSize: `20px` }}>
                Entry/Exit Log
              </h3>
            </Grid>
            <Grid item xs={1} md={1}>
              {/* <IconButton aria-label="delete" color="primary" size="large">
                                <DownloadForOfflineIcon />
                            </IconButton> */}
              <Button variant="contained" onClick={(e) => downloadCsvByApi()}>
                Download
              </Button>
              <CSVLink
                data={downloadcsv}
                filename={`${new Date().getTime()}.csv`}
                className="hidden"
                ref={csvLink}
                target="_blank"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ paddingLeft: `30px`, paddingRight: `50px` }}
        >
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <TextField
                id="name"
                label="Name"
                //placeholder="Placeholder"
                multiline
                size="small"
                style={{ width: `100%`, backgroundColor: `white` }}
                onChange={handleChange}
                value={inputSearch.name}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                id="block"
                label="block"
                //placeholder="Placeholder"
                multiline
                size="small"
                style={{ width: `100%`, backgroundColor: `white` }}
                onChange={handleChange}
                value={inputSearch.block}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="contactNo"
                label="Mobile"
                //placeholder="Placeholder"
                multiline
                size="small"
                style={{ width: `100%`, backgroundColor: `white` }}
                onChange={handleChange}
                value={inputSearch.contactNo}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="vehicleNo"
                label="Vehicle"
                //placeholder="Placeholder"
                multiline
                size="small"
                style={{ width: `100%`, backgroundColor: `white` }}
                onChange={handleChange}
                value={inputSearch.vehicleNo}
              />
            </Grid>
            <Grid item xs={2}>
              <Select
                value={inputSearch.monthWise}
                onChange={handleMonthChange}
                label={"Select Month"}
                displayEmpty
                size="small"
                inputProps={{ "aria-label": "Without label" }}
                style={{ width: `100%`, backgroundColor: `white` }}
              >
                <MenuItem disabled value="">
                  <em>Select Month</em>
                </MenuItem>
                {monthData &&
                  monthData.map((x) => <MenuItem value={x}>{x}</MenuItem>)}
              </Select>
            </Grid>
            <Grid item xs={2}>
              <Select
                value={inputSearch.category}
                onChange={handleCategoryChange}
                displayEmpty
                size="small"
                inputProps={{ "aria-label": "Without label" }}
                style={{ width: `100%`, backgroundColor: `white` }}
              >
                <MenuItem disabled value="">
                  <em>Select Category</em>
                </MenuItem>
                {category &&
                  category.map((x) => <MenuItem value={x}>{x}</MenuItem>)}
              </Select>
            </Grid>
            <Grid item xs={1}>
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    onClick={SearchByGlobal}
                    size="large"
                    //style={{marginLeft: `10%`}} //35
                  >
                    <SearchIcon />
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={handleFilterReset}
                    style={{ backgroundColor: `#F06363` }}
                  >
                    <RestartAltIcon />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Grid item xs={12} style={{ paddingLeft: `30px`, paddingRight: `30px` }}>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            //title={"Register"}
            //options={options}
            columns={columns}
            data={list}
            options={options}
            components={{
              Tooltip: CustomTooltip,
              icons: {
                FilterIcon: SearchIcon,
              },
            }}
          />
        </ThemeProvider>
      </Grid>
      <Snackbar open={progress} autoHideDuration={3000}>
        <Alert severity="info">Downloading</Alert>
      </Snackbar>
    </React.Fragment>
  );
}
