import MUIDataTable from "mui-datatables";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import React, { useState, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Fab from "@mui/material/Fab";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import AddIcon from "@mui/icons-material/Add";
import DataServices from "../Api/DataServices";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from "@mui/material/Tooltip";
import Slide from "@mui/material/Slide";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { CSVLink } from "react-csv";
import Alert from "@mui/material/Alert";
//import DownloadIcon from '@mui/icons-material/Download';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
var monthName = new Array(
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
);
var d = new Date();
let monthData = [];
export default function Register() {
  const csvLink = useRef();
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [list, setList] = React.useState([]);
  const [page, setPage] = useState(0);
  const [blockList, setBlockList] = React.useState([]);
  const [floorList, setFloorList] = React.useState([]);
  const [unitList, setUnitList] = React.useState([]);
  const [successBox, setSuccessBox] = React.useState(false);
  const [successMsg, setSuccessMsg] = React.useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [details, setDetails] = React.useState([]);
  const [element, setElement] = React.useState("");
  const [deleteValue, setDeleteValue] = React.useState("");
  const [openPop, setopenPop] = React.useState(false);
  const [selectSearch, setSelectSearch] = React.useState("");
  const [selectSearchText, setSelectSearchText] = React.useState("");
  const [offset, setOffset] = React.useState(0);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = React.useState(false);
  const [inputField, setInputField] = useState({
    id: "",
    name: "",
    block: "",
    floor: "",
    unit: "",
    contactNo: "",
    vehicleNo: "",
  });
  const [inputSearch, setInputSearch] = useState({
    name: "",
    block: "",
    floor: "",
    unit: "",
    contactNo: "",
    vehicleNo: "",
  });
  const [vehicleField, setVehicleField] = useState({
    vehicleNo1: "",
    vehicleNo2: "",
    vehicleNo3: "",
    vehicleNo4: "",
  });
  const [showVehicleField, setShowVehicleField] = useState({
    vehicleNo1: true,
    vehicleNo2: false,
    vehicleNo3: false,
    vehicleNo4: false,
  });
  const [searchName, setSearchName] = React.useState("");
  const [searchBlock, setSearchBlock] = React.useState("");
  const [searchFloorUnit, setSearchFloorUnit] = React.useState("");
  const [searchMobile, setSearchMobile] = React.useState("");
  const [searchVehicleNos, setSearchVehicleNos] = React.useState("");
  const [downloadcsv, setDownloadcsv] = React.useState([]);
  const arrayList = ["name", "blockNo", "floorUnit", "mobileNo", "vehicleNos"];
  const handleClose = () => {
    setOpen(false);
    resetForm();
    setErrorMsg("");
  };
  const [progress, setProgress] = useState(false);
  const [bDownloadReady, setDownloadReady] = useState(false);

  const handleFilterReset = () => {
    setPage(0);
    setSearchName("");
    setSearchBlock("");
    setSearchFloorUnit("");
    setSearchMobile("");
    setSearchVehicleNos("");
    setSelectSearchText("");
    setSelectSearch("");
    setInputSearch({
      name: "",
      block: "",
      floor: "",
      unit: "",
      contactNo: "",
      vehicleNo: "",
    });
    regFunction();
  };

  const addVehicle = () => {
    if (vehicleField.vehicleNo1.length > 0) {
      let showTextField = { ...showVehicleField };
      showTextField.vehicleNo2 = true;
      setShowVehicleField(showTextField);
    }
    if (vehicleField.vehicleNo2.length > 0) {
      let showTextField = { ...showVehicleField };
      showTextField.vehicleNo2 = true;
      showTextField.vehicleNo3 = true;
      setShowVehicleField(showTextField);
    }
    if (vehicleField.vehicleNo3.length > 0) {
      let showTextField = { ...showVehicleField };
      showTextField.vehicleNo2 = true;
      showTextField.vehicleNo3 = true;
      showTextField.vehicleNo4 = true;
      setShowVehicleField(showTextField);
    }
  };

  const VehicleChange = (e) => {
    setVehicleField({ ...vehicleField, [e.target.id]: e.target.value });
  };

  const resetForm = () => {
    let data = {
      id: "",
      name: "",
      block: "",
      floor: "",
      unit: "",
      contactNo: "",
      vehicleNo: "",
    };
    let vehicleField = {
      vehicleNo1: "",
      vehicleNo2: "",
      vehicleNo3: "",
      vehicleNo4: "",
    };
    let showField = {
      vehicleNo1: true,
      vehicleNo2: false,
      vehicleNo3: false,
      vehicleNo4: false,
    };
    setInputField(data);
    setVehicleField(vehicleField);
    setShowVehicleField(showField);
    setErrorMsg("");
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTable: {
          styleOverrides: {
            root: {
              backgroundColor: "#282c34 !important",
            },
            paper: {
              boxShadow: "none",
            },
          },
        },
        MUIDataTableBodyRow: {
          styleOverrides: {
            root: {
              "&:nth-child(odd)": {
                backgroundColor: "#A0D1FF !important",
              },
              "&:nth-child(even)": {
                backgroundColor: "#41A4FF !important",
              },
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              padding: "8px",
              //backgroundColor: '#CDCAC6 ',
            },
          },
        },
        MuiToolbar: {
          styleOverrides: {
            root: {
              backgroundColor: "#666666 !important",
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            head: {
              backgroundColor: "#666666 !important",
              color: `white`,
              fontWeight: `bold`,
              fontSize: `16px`,
            },
          },
        },
        MUIDataTableSelectCell: {
          styleOverrides: {
            headerCell: {
              backgroundColor: "blue !important",
            },
          },
        },
        MuiTableFooter: {
          styleOverrides: {
            root: {
              backgroundColor: `#666666 !important`,
              "& .MuiToolbar-root": {
                //backgroundColor: 'white',
                color: `white`,
              },
            },
          },
        },
      },
    });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    //transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    //p: 4,
  };

  const columns = [
    {
      name: "id",
      label: "S/No.",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <span>
              {offset
                ? offset + tableMeta.rowIndex + 1
                : tableMeta.rowIndex + 1}
            </span>
          );
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "blockNo",
      label: "Block",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "floorUnit",
      label: "Floor",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "unitNumber",
      label: "Unit",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "mobileNo",
      label: "Contact",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "vehicleNos",
      label: "Vehicle Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "Action",
      label: "More changes",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  variant="contained"
                  //style={{  marginRight: `10px` }}
                  //   disabled={value.disabled}
                  onClick={() => handleClick(tableMeta)}
                >
                  Edit / Update
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: `#F06363`, marginRight: `10px` }}
                  //   disabled={value.disabled}
                  onClick={() => openDeletePopup(tableMeta)} // handleClick(value, tableMeta, updateValue)
                >
                  Delete
                </Button>
              </div>
            </>
          );
        },
      },
    },
  ];

  const CustomTooltip = ({ children, ...props }) => {
    return (
      <Tooltip
        {...props}
        title={props.title === "Filter Table" ? "Search" : props.title}
      >
        {children}
      </Tooltip>
    );
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const options = {
    //filter: true,
    //serverSide: true,
    selectableRows: false,
    search: false,
    count: count,
    page: page,
    filter: false,
    download: false,
    serverSide: true,
    /* downloadOptions: { 
            filename: new Date(),           
            // new API change added here
            customCSVdata: details,
            filterOptions: { useDisplayedColumnsOnly: true },
            useDisplayedRowsOnly: true,
            
        },
        onDownload: (buildHead, buildBody, columns, data) => {
            return downloadcsv;
        },  */
    // onDownload: (buildHead, buildBody, columns, data) => {
    //     console.log(buildHead, buildBody, columns, data)
    //     //let val = `${buildHead(columns)}${buildBody(this.excelDetails)}`.trim();
    //     /* if(this.excelDetails != undefined || this.excelDetails != null){
    //         console.log(this.excelDetails)
    //         return "\uFEFF" + buildHead(columns) + buildBody(this.excelDetails);
    //     }     */
    //     buildHead = () => {
    //         return columns
    //      }
    //      buildBody = () => {
    //       return this.excelDetails;
    //      }
    //     return "\uFEFF" + buildHead() + buildBody();
    // },
    print: false,
    viewColumns: false,
    rowsPerPageOptions: [10],
    filterType: "custom",
    responsive: "vertical",
    filterOptions: {
      filter: {
        title: "",
        reset: "",
      },
      display: (filterList, onChange, index, column) => {
        return (
          <>
            {index == 1 && (
              <FormControl variant="outlined" style={{ width: `100%` }}>
                <InputLabel htmlFor="outlined-adornment-password">
                  Name
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  value={searchName}
                  onChange={searchByName}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={searchByName}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Search by name"
                />
              </FormControl>
            )}
            {index == 2 && (
              <FormControl variant="outlined" style={{ width: `100%` }}>
                <InputLabel htmlFor="outlined-adornment-password">
                  Block
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  value={searchBlock}
                  onChange={searchByBlock}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={searchByBlock}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Search by block"
                />
              </FormControl>
            )}

            {index == 3 && (
              <FormControl variant="outlined" style={{ width: `100%` }}>
                <InputLabel htmlFor="outlined-adornment-password">
                  Floor/Unit
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  value={searchFloorUnit}
                  onChange={searchByFloorUnit}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={searchByFloorUnit}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Search by FloorUnit"
                />
              </FormControl>
            )}

            {index == 4 && (
              <FormControl variant="outlined" style={{ width: `100%` }}>
                <InputLabel htmlFor="outlined-adornment-password">
                  Mobile
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  value={searchMobile}
                  onChange={searchByMobile}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={searchByMobile}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Search by Mobile"
                />
              </FormControl>
            )}

            {index == 5 && (
              <FormControl variant="outlined" style={{ width: `100%` }}>
                <InputLabel htmlFor="outlined-adornment-password">
                  Vehicle Number
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  value={searchVehicleNos}
                  onChange={searchByVehicle}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={searchByVehicle}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Search by Vehicle Number"
                />
              </FormControl>
            )}
          </>
        );
      },
    },
    customFilterDialogFooter: (filterList) => {
      return (
        <div>
          <Button
            variant="outlined"
            // variant="text"
            style={{ marginRight: `10px`, float: `right` }}
            onClick={() => handleFilterReset()} // handleClick(value, tableMeta, updateValue)
          >
            Reset
          </Button>
        </div>
      );
    },
    searchPlaceholder: "Search by Vehicle Number",
    pagination: true,
    //rowsPerPage: 100,
    rowsPerPageOptions: [10],
    textLabels: {
      filter: {
        title: "",
        reset: "",
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          if (list.length > 9) {
            changePage(tableState.page);
          }
          break;
        case "search":
          onSearch(tableState.searchText);
          break;
        case "resetFilters":
          setSearchName("");
          setSearchBlock("");
          setSearchFloorUnit("");
          setSearchMobile("");
          setSearchVehicleNos("");
          break;
      }
    },
  };

  useEffect(() => {
    onSearch();
  }, [page]);

  useEffect(() => {
    if (csvLink && csvLink.current && bDownloadReady) {
      csvLink.current.link.click();
      setProgress(false);
      setDownloadReady(false);
    }
  }, [bDownloadReady]);

  function regFunction() {
    /*  let data = {
             pageNo: 0
         }
         registerTable(data) */
    console.log("regFunction");
    onSearch();
  }

  function registerTable(data) {
    DataServices.getRegisterLists(data).then((d) => {
      let data = d && d.data && d.data.data && d.data.data.content;
      let element = d && d.data && d.data.data && d.data.data.totalElements;
      let offset =
        d &&
        d.data &&
        d.data.data &&
        d.data.data.pageable &&
        d.data.data.pageable.offset;
      offset = offset ? offset - 10 : offset;
      setList(data);
      setDetails(arrayToCSV(data));
      setElement(element);
      setOffset(offset);
    });
  }

  useEffect(() => {
    monthData = [];
    for (let i = 0; i <= 11; i++) {
      monthData.push(monthName[d.getMonth()] + " " + d.getFullYear());
      d.setMonth(d.getMonth() - 1);
    }
    DataServices.getCondoBlocks().then((d) => {
      let data = d && d.data && d.data.data;
      setBlockList(data);
    });
    let data = {
      element: element,
    };
    //regFunction();
  }, []);

  useEffect(() => {
    DataServices.getFloors(inputField.block).then((d) => {
      let floor = inputField.floor;
      let data = d && d.data && d.data.data;
      setFloorList(data);
      setInputField({ ...inputField, [floor]: floor });
    });
  }, [inputField.block]);

  useEffect(
    () => {
      let floor = inputField.floor;
      DataServices.getUnits(inputField.block, inputField.floor).then((d) => {
        let data = d && d.data && d.data.data;
        setUnitList(data);
      });
    },
    [inputField.floor],
    () => {
      //setInputField({ ...inputField, floor: floor })
    }
  );

  const handleSnackClose = () => {
    setSuccessBox(false);
  };

  function changePage(page) {
    setPage(page);
  }

  function handleClick(value) {
    let title = value == "Add" ? "Add" : "Edit/Update";
    console.log("title", title);
    setTitle(title);
    if (value != "Add") {
      let d = list[value.rowIndex];
      console.log("d", d);
      const temp = d.vehicleNos && d.vehicleNos.split(",");
      setInputField({
        id: d.id,
        name: d.name,
        block: d.blockNo,
        floor: d.floorUnit && d.floorUnit,
        unit: d.unitNumber && d.unitNumber,
        contactNo: d.mobileNo,
        vehicleNo: d.vehicleNos,
      });
      setVehicleField({
        vehicleNo1: temp && temp[0] ? temp[0] : "",
        vehicleNo2: temp && temp[1] ? temp[1] : "",
        vehicleNo3: temp && temp[2] ? temp[2] : "",
        vehicleNo4: temp && temp[3] ? temp[3] : "",
      });
      setShowVehicleField({
        vehicleNo1: temp && temp[0] ? true : true,
        vehicleNo2: temp && temp[1] ? true : false,
        vehicleNo3: temp && temp[2] ? true : false,
        vehicleNo4: temp && temp[3] ? true : false,
      });
    }
    setOpen(true);
  }

  function openDeletePopup(value) {
    setDeleteValue(value.rowIndex);
    setopenPop(true);
  }

  function closeDeletePopup() {
    setopenPop(false);
  }

  function handleDelete() {
    let d = list[deleteValue];
    let data = {
      blockNo: d.blockNo,
      floorUnit: d.floorUnit,
      id: d.id,
      mobileNo: d.mobileNo,
      name: d.name,
      vehicleNos: d.vehicleNos,
    };
    DataServices.deleteData(data).then((resp) => {
      setSuccessMsg("Deleted Successfully.....");
      resetForm();
      regFunction();
      handleFilterReset();
      setopenPop(false);
    });
  }

  const handleSubmit = () => {
    setLoading(true); // Start loader
    const str = Object.values(vehicleField).filter(Boolean).join(", ");
    if (title != "Add") {
      let data = {
        id: inputField.id,
        blockNo: inputField.block,
        floorUnit: inputField.floor + "-" + inputField.unit,
        mobileNo: inputField.contactNo,
        name: inputField.name,
        vehicleNos: str,
      };
      DataServices.updateUser(data)
        .then((d) => {
          setPage(0);
          setSuccessBox(true);
          setSuccessMsg("Updated Successfully.....");
          resetForm();
          regFunction();
          setOpen(false);
        })
        .catch((error) => {
          setErrorMsg("An error occurred. Please try again.");
        })
        .finally(() => setLoading(false));
    } else {
      let data = {
        blockNo: inputField.block,
        floorUnit: inputField.floor + "-" + inputField.unit,
        mobileNo: inputField.contactNo,
        name: inputField.name,
        vehicleNos: str,
      };
      DataServices.createUser(data)
        .then((d) => {
          console.log("createUser response", d);
          setPage(0);
          setSuccessBox(true);
          setSuccessMsg("Registered Successfully.....");
          resetForm();
          regFunction();
          setOpen(false);
        })
        .catch((error) => {
          const errorReason =
            error.response?.data?.error?.reason ||
            "An error occurred. Please try again.";
          setErrorMsg(errorReason);
        })
        .finally(() => setLoading(false));
    }
  };

  const handleChange = (e) => {
    setInputField({ ...inputField, [e.target.id]: e.target.value });
  };

  const handleSearchChange = (e) => {
    setInputSearch({ ...inputSearch, [e.target.id]: e.target.value });
  };

  const handleBlockChange = (e) => {
    setInputField({ ...inputField, block: e.target.value });
    DataServices.getFloors(e.target.value).then((d) => {
      let data = d && d.data && d.data.data;
      setFloorList(data);
    });
  };

  const handleFloorChange = (e) => {
    setInputField({ ...inputField, floor: e.target.value });
    DataServices.getUnits(inputField.block, e.target.value).then((d) => {
      let data = d && d.data && d.data.data;
      setUnitList(data);
    });
  };

  const handleUnitChange = (e) => {
    setInputField({ ...inputField, unit: e.target.value });
  };

  const searchByName = (e) => {
    /* if(e.type == 'change'){
            setSearchName(e.target.value)
        }
        if(e.type == 'click'){
            onSearch(searchName, "name")
        }     */
    setSearchName(e.target.value);
  };

  const searchByBlock = (e) => {
    /* if(e.type == 'change'){
            setSearchBlock(e.target.value)
        }
        if(e.type == 'click'){
            onSearch(searchBlock, "blockNo")
        }   */
    setSearchBlock(e.target.value);
  };

  const searchByFloorUnit = (e) => {
    /* if(e.type == 'change'){
            setSearchFloorUnit(e.target.value)
        }
        if(e.type == 'click'){
            onSearch(searchFloorUnit, "floorUnit")
        }    */
    setSearchFloorUnit(e.target.value);
  };

  const searchByMobile = (e) => {
    /* if(e.type == 'change'){
            setSearchMobile(e.target.value)
        }
        if(e.type == 'click'){
            onSearch(searchMobile, "mobileNo")
        }    */
    setSearchMobile(e.target.value);
  };

  const searchByVehicle = (e) => {
    /* if(e.type == 'change'){
            setSearchVehicleNos(e.target.value)
        }
        if(e.type == 'click'){
            onSearch(searchVehicleNos, "vehicleNos")
        }   */
    setSearchVehicleNos(e.target.value);
  };

  const SearchByGlobal = () => {
    /* if(selectSearch.length == 0){
            regFunction();
        }
        else{
            onSearch(selectSearchText, selectSearch)
        }     */
    onSearch();
  };

  const handleSelectSearch = (e) => {
    setSelectSearch(e.target.value);
  };

  const searchBySelect = (e) => {};

  const onSearch = (e, x) => {
    console.log("onSearch");
    let data = {
      pageNo: page,
      field: inputSearch,
    };
    DataServices.searchFilter(data).then((d) => {
      let data = d && d.data && d.data.data && d.data.data.content;
      let offset =
        d &&
        d.data &&
        d.data.data &&
        d.data.data.pageable &&
        d.data.data.pageable.offset;
      let countData = d && d.data && d.data.data && d.data.data.totalElements;
      offset = offset ? offset - 10 : offset;
      setList(data);
      setDetails(arrayToCSV(data));
      setCount(countData);
      setOffset(offset);
      setSearchName("");
      setSearchBlock("");
      setSearchFloorUnit("");
      setSearchMobile("");
      setSearchVehicleNos("");
    });
    /* DataServices.downloadExcel(data).then(res => {
            let details = res && res.data
            console.log(details)
            setDownloadcsv(details)
        }) */
  };

  const downloadCsvByApi = () => {
    setProgress(true);
    let data = {
      pageNo: page,
      field: inputSearch,
    };
    DataServices.downloadExcel(data).then((res) => {
      let details = res && res.data;
      setDownloadcsv(details);
      setDownloadReady(true);
    });
  };

  function arrayToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";

    return array.reduce((str, next) => {
      str +=
        `${Object.values(next)
          .map((value) => `"${value}"`)
          .join(",")}` + "\r\n";
      return str;
    }, str);
  }

  return (
    <React.Fragment>
      <Grid container>
        <Grid
          item
          xs={10}
          style={{ paddingLeft: `20px`, paddingRight: `30px` }}
        >
          <h3 style={{ margin: `0px`, padding: `10px`, fontSize: `20px` }}>
            Register
          </h3>
        </Grid>
        <Grid item xs={1}>
          <Fab
            size="small"
            color="primary"
            aria-label="add"
            onClick={() => handleClick("Add")}
          >
            <AddIcon />
          </Fab>
        </Grid>
        <Grid item xs={1} style={{ padding: `2px`, marginLeft: `-30px` }}>
          <Button variant="contained" onClick={(e) => downloadCsvByApi()}>
            Download
          </Button>
          <CSVLink
            data={downloadcsv}
            filename={`${new Date().getTime()}.csv`}
            className="hidden"
            ref={csvLink}
            target="_blank"
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          item
          xs={12}
          style={{ paddingLeft: `20px`, paddingRight: `50px` }}
        >
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <TextField
                id="name"
                label="Name"
                //placeholder="Placeholder"
                multiline
                size="small"
                style={{ width: `100%`, backgroundColor: `white` }}
                onChange={handleSearchChange}
                value={inputSearch.name}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="block"
                label="block"
                //placeholder="Placeholder"
                multiline
                size="small"
                style={{ width: `100%`, backgroundColor: `white` }}
                onChange={handleSearchChange}
                value={inputSearch.block}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="floor"
                label="floor"
                //placeholder="Placeholder"
                multiline
                size="small"
                style={{ width: `100%`, backgroundColor: `white` }}
                onChange={handleSearchChange}
                value={inputSearch.floor}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="contactNo"
                label="Mobile"
                //placeholder="Placeholder"
                multiline
                size="small"
                style={{ width: `100%`, backgroundColor: `white` }}
                onChange={handleSearchChange}
                value={inputSearch.contactNo}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="vehicleNo"
                label="Vehicle"
                //placeholder="Placeholder"
                multiline
                size="small"
                style={{ width: `100%`, backgroundColor: `white` }}
                onChange={handleSearchChange}
                value={inputSearch.vehicleNo}
              />
            </Grid>
            <Grid item xs={1}>
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    onClick={SearchByGlobal}
                    size="large"
                    //style={{marginLeft: `10%`}} //35
                  >
                    <SearchIcon />
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={handleFilterReset}
                    style={{ backgroundColor: `#F06363` }}
                  >
                    <RestartAltIcon />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          style={{ paddingLeft: `30px`, paddingRight: `30px` }}
        >
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              //title={"Register"}
              //options={options}
              columns={columns}
              data={list}
              options={options}
              components={{
                Tooltip: CustomTooltip,
                icons: {
                  FilterIcon: SearchIcon,
                },
              }}
            />
          </ThemeProvider>
        </Grid>
        <Snackbar open={progress} autoHideDuration={3000}>
          <Alert severity="info">Downloading</Alert>
        </Snackbar>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title && title}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText> */}
          {errorMsg && (
            <div style={{ color: "red", marginBottom: "10px" }}>{errorMsg}</div>
          )}
          <Grid container spacing={4} style={{ paddingTop: `20px` }}>
            <Grid item xs={12} md={6}>
              <TextField
                id="name"
                label="Name"
                //placeholder="Placeholder"
                multiline
                style={{ width: `100%` }}
                onChange={handleChange}
                value={inputField.name}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <Select
                        labelId="demo-simple-select-label"
                        id="block"
                        value={inputField.block}
                        label="block"
                        style={{width: `100%`}}
                       onChange={handleBlockChange}
                    >
                       
                        {blockList && blockList.map((item) => {
                            return <MenuItem value={item.blockId}>{item.blockNumber}</MenuItem>
                        })}
                    </Select> */}
              <TextField
                id="block"
                select
                label="Block"
                value={inputField.block}
                onChange={handleBlockChange}
                style={{ width: `100%` }}
                variant="outlined"
              >
                {blockList &&
                  blockList.map((item) => {
                    return (
                      <MenuItem key={item.blockId} value={item.blockId}>
                        {item.blockNumber}
                      </MenuItem>
                    );
                  })}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="floor"
                select
                label="Floor"
                value={inputField.floor}
                onChange={handleFloorChange}
                style={{ width: `100%` }}
                variant="outlined"
              >
                {floorList &&
                  floorList.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="unit"
                select
                label="Unit"
                value={inputField.unit}
                onChange={handleUnitChange}
                style={{ width: `100%` }}
                variant="outlined"
              >
                {unitList &&
                  unitList.map((item) => {
                    return <MenuItem value={item}>{item}</MenuItem>;
                  })}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="contactNo"
                label="Contact Number"
                // placeholder="Contact Number"
                type="number"
                multiline
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                style={{ width: `100%` }}
                onChange={handleChange}
                value={inputField.contactNo}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="vehicleNo1"
                label="Vehicle Number1"
                // placeholder="Emai lId"
                multiline
                style={{ width: `100%` }}
                onChange={VehicleChange}
                value={vehicleField.vehicleNo1}
              />
            </Grid>
            {showVehicleField.vehicleNo2 && (
              <Grid item xs={12} md={6}>
                <TextField
                  id="vehicleNo2"
                  label="Vehicle Number2"
                  // placeholder="Emai lId"
                  multiline
                  style={{ width: `100%` }}
                  onChange={VehicleChange}
                  value={vehicleField.vehicleNo2}
                />
              </Grid>
            )}
            {showVehicleField.vehicleNo3 && (
              <Grid item xs={12} md={6}>
                <TextField
                  id="vehicleNo3"
                  label="Vehicle Number3"
                  // placeholder="Emai lId"
                  multiline
                  style={{ width: `100%` }}
                  onChange={VehicleChange}
                  value={vehicleField.vehicleNo3}
                />
              </Grid>
            )}
            {showVehicleField.vehicleNo4 && (
              <Grid item xs={12} md={6}>
                <TextField
                  id="vehicleNo4"
                  label="Vehicle Number4"
                  // placeholder="Emai lId"
                  multiline
                  style={{ width: `100%` }}
                  onChange={VehicleChange}
                  value={vehicleField.vehicleNo4}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={addVehicle}>Add Vehicle</Button>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={successBox}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        message={successMsg}
        //action={action}
      />
      {openPop && (
        <div>
          <Dialog
            open={openPop}
            TransitionComponent={Transition}
            keepMounted
            onClose={closeDeletePopup}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Attention"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure, you want to delete it!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDeletePopup}>Disagree</Button>
              <Button onClick={handleDelete}>Agree</Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </React.Fragment>
  );
}
