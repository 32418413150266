import http from "./registerApi";

const getUserDetails = (params) => {
  return http.get("user/user/email/siva@gmail.com/group/MASTER", { params });
};

const getUserAfterLogin = (data) => {
  return http.get(`user/email/${data}`);
};

const registerVisitor = (data) => {
  return http.post("visitorInfo/vistorRegistration", data);
};

const registerAdhocVisitor = (data) => {
  return http.post("visitorInfo/adhocVistorRegistration", data);
};

const getPendingStatus = (data) => {
  return http.get(`user/email/${data}/status/PENDINGAPPROVAL`);
};
const getUsersBF = (block, floor) => {
  return http.get(`user/block/${block}/floor/${floor}`);
};
const getUsersByVehicle = (vehicleno) => {
  return http.get(`user/vehicleno/${vehicleno}`);
};
const getImportantContacts = () => {
  return http.get(`contact/list`);
};
const getNotification = (data) => {
  return http.get(`notification/active`);
};

const registerUser = (data, role) => {
  return http.post(`user/${role}`, data);
};

const getDetailByReferal = (referal) => {
  return http.get(`user/referalcode/${referal}`);
};

const getCurrentVisitorAudit = () => {
  return http.get(`visitorInfo/audit/current`);
};

// const registerVisitor = (data) => {
//     return http.post("visitorInfo/vistorRegistration",data);
// }

const updateVisitorSector = (data) => {
  return http.put(`user/emailId/${data.mailId}/status/${data.statusbar}`);
};

const updateFcmToken = (data) => {
  return http.put(`user/email/${data.mailId}/fcm/${data.token}`);
};

const updateUserStatus = (data) => {
  return http.put(`user/status`, data);
};

// NEW SERVICES

const loginService = (data) => {
  return http.post(`user/login`, data);
};

const generateRefrealCode = (data) => {
  return http.put(`user/email/${data}/referalcode`);
};

const getVehicleAudits = (data) => {
  return http.get(`visitorInfo/audit`);
};

const getVehicleAuditData = (data) => {
  return http.get(`visitorInfo/audit/data`, { params: data });
};

const getVehicleHistory = () => {
  return http.get(`visitorInfo/qrcode`);
};

const getCondoBlocks = () => {
  return http.get(`condo/blocks`);
};

const getFloors = (block) => {
  return http.get(`condo/floors/${block}`);
};

const getUnits = (block, floor) => {
  return http.get(`condo/units/${block}/${floor}`);
};

const openBoom = () => {
  return http.post(`visitorInfo/open/boom`);
};

const getRegisterLists = (data) => {
  return http.get(`/vehicle/getData`); //?pageNo=${data.pageNo + 1}&pageSize=10
};

const createUser = (data) => {
  return http.post("/vehicle/create", data);
};

const updateUser = (data) => {
  return http.put("vehicle/updateData", data);
};

const searchFilter = (data) => {
  let x = data.field;
  let b = x.name.length > 0 ? `&name=${data.field.name}` : "";
  let c = x.block.length > 0 ? `&blockNo=${data.field.block}` : "";
  let d = x.vehicleNo.length > 0 ? `&vehicleNos=${data.field.vehicleNo}` : "";
  let e = x.contactNo.length > 0 ? `&mobileNo=${data.field.contactNo}` : "";
  let k = x.floor.length > 0 ? `&floorUnit=${data.field.floor}` : "";
  let f = b + c + d + e + k;
  return http.get(`/vehicle/search?pageNo=${data.pageNo}&pageSize=10${f}`); //pageNumber=${data.pageNo + 1}&pageSize=10&
};

const masterLogin = (data) => {
  return http.post("/user/masterlogin", data);
};

const downloadExcel = (data) => {
  let x = data.field;
  let b = x.name.length > 0 ? `&name=${data.field.name}` : "";
  let c = x.block.length > 0 ? `&blockNo=${data.field.block}` : "";
  let d = x.vehicleNo.length > 0 ? `&vehicleNos=${data.field.vehicleNo}` : "";
  let e = x.contactNo.length > 0 ? `&mobileNo=${data.field.contactNo}` : "";
  let k = x.floor.length > 0 ? `&floorUnit=${data.field.floor}` : "";
  let f = b + c + d + e + k;
  let url;
  if (data) {
    url = `/vehicle/csv?${f}`;
  } else {
    url = `/vehicle/csv`;
  }
  return http.get(url);
};

const deleteData = (data) => {
  return http.put(`/vehicle/deleteData`, data);
};

const getEntryExit = (data) => {
  return http.get(
    `/vehicle/search/entryexit?pageNo=${data.pageNo}&pageSize=10&sortBy=id`
  );
};

const searchEntryExit = (data) => {
  let x = data.field;
  let a =
    x.category == `All`
      ? ""
      : x.category.length > 0
      ? `&category=${data.field.category}`
      : "";
  let b = x.name.length > 0 ? `&name=${data.field.name}` : "";
  let c = x.block.length > 0 ? `&blockNo=${data.field.block}` : "";
  let d = x.vehicleNo.length > 0 ? `&vehicleNos=${data.field.vehicleNo}` : "";
  let e = x.contactNo.length > 0 ? `&mobileNo=${data.field.contactNo}` : "";
  let k = x.monthWise.length > 0 ? `&monthYear=${data.field.monthWise}` : "";
  let f = a + b + c + d + e + k;
  return http.get(
    `/vehicle/search/entryexit?pageNo=${data.pageNo}&pageSize=10${f}`
  );
};

const downloadEntryExitExcel = (data) => {
  let x = data && data.field && data.field;
  let b = x && x.name && x.name.length > 0 ? `&name=${data.field.name}` : "";
  let c =
    x && x.block && x.block.length > 0 ? `&blockNo=${data.field.block}` : "";
  let d =
    x && x.vehicleNo && x.vehicleNo.length > 0
      ? `&vehicleNos=${data.field.vehicleNo}`
      : "";
  let e =
    x && x.contactNo && x.contactNo.length > 0
      ? `&mobileNo=${data.field.contactNo}`
      : "";
  let k =
    x && x.monthWise && x.monthWise.length > 0
      ? `&monthYear=${data.field.monthWise}`
      : "";
  let f = b + c + d + e + k;
  let url;
  if (data) {
    url = `/vehicle/search/entryexit/csv?${f}`;
  } else {
    url = `/vehicle/search/entryexit/csv`;
  }
  return http.get(url);
};

//i/vehice/search?blockNo=8&floorUnit=1&mobileNo=9&name=s&pageNumber=0&pageSize=10&vehicleNos=S
//&category=${data.field.category}&name=${data.field.name}&blockNo=${data.field.block}&vehicleNos=${data.field.vehicleNo}&mobileNo=${data.field.contactNo}

// other CRUD methods

export default {
  getUserDetails,
  getUsersBF,
  getUsersByVehicle,
  getImportantContacts,
  getUserAfterLogin,
  registerVisitor,
  registerAdhocVisitor,
  getPendingStatus,
  updateVisitorSector,
  registerUser,
  loginService,
  generateRefrealCode,
  getNotification,
  updateUserStatus,
  getDetailByReferal,
  getVehicleAudits,
  getVehicleHistory,
  updateFcmToken,
  getCurrentVisitorAudit,
  getCondoBlocks,
  getFloors,
  getUnits,
  openBoom,
  getVehicleAuditData,
  getRegisterLists,
  createUser,
  updateUser,
  searchFilter,
  masterLogin,
  downloadExcel,
  deleteData,
  getEntryExit,
  searchEntryExit,
  downloadEntryExitExcel,
};
