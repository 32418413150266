import axios from "axios";
export default axios.create({
  //baseURL: 'https://api.pixvisonz.com/v1/',
  //baseURL: 'http://192.168.29.201:8080/api/',
  //baseURL: 'https://condo.pixvisonz.com/api/',
  baseURL: "https://condo.pixvisonz.com/api/",
  //baseURL: 'http://localhost:8008/api/',
  headers: {
    "Content-type": "application/json",
  },
});
