import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
const AuthGaurd = ({ children }) => {
  const permission = localStorage.getItem("Session");

  const history = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname === "/home" &&
      (permission == undefined || permission == null)
    ) {
      history("/");
    }
  }, [location.pathname]);
  return children;
};

export default AuthGaurd;
